import {HttpClient} from "../http";

export class UserDetailsService extends HttpClient {

    public static readonly EXERCISE_RETURN_LINK_KEY = "EXERCISE_RETURN_LINK";

    static verifyAuthentication() {
        this.toJson(this.doGet("/exercise-api/user-details"))
            .then(res => {
                if (!res.authenticated) {
                    const exerciseReturnLink = this.getExerciseReturnLink();
                    window.location.href = exerciseReturnLink || res.returnLink;
                } else {
                    sessionStorage.setItem(this.EXERCISE_RETURN_LINK_KEY, res.exerciseReturnLink);
                }
            })
    }

    static getExerciseReturnLink() {
        return sessionStorage.getItem(this.EXERCISE_RETURN_LINK_KEY);
    }

}
