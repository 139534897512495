import React, {CSSProperties, useMemo} from "react";
import {useTimer} from "../../hooks/useTimer";
import "./Overlay.css";

const OVERLAY_STYLES: CSSProperties = {
    backgroundColor: "rgba(31, 50, 69, 0.91)",
    position: "absolute",
    top: 0,
    height: "100%",
};

type ButtonType = "primary" | "secondary" | "danger" | "warning" | "dark";

type ButtonConfiguration = {
    text: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    type: ButtonType;
}

export type OverlayConfiguration = {
    show: boolean;
    headline: string;
    text?: string;
    buttons?: ButtonConfiguration[];
    icon?: any;
    closeAfter?: number;
    showModal?: boolean;
    size?: "full" | "flowable" | "instructions";
}

type Props = { configuration: OverlayConfiguration, onClose: () => any };

export const Overlay = ({configuration, onClose}: Props) => {
    useTimer({
        timeout: configuration.closeAfter,
        onTimerComplete: onClose
    });

    const styles = useMemo(() => {
        if (configuration.size === "flowable") {
            return {width: "calc(100% - 480px)", left: "0"}
        } else if (configuration.size === "instructions") {
            return {width: "480px", left: "75%"}
        }
        return {width: "100%", left: "0"};

    }, [configuration.size])

    if (!configuration.show) {
        return <></>;
    }

    return (
        <div className="mask text-light d-flex justify-content-center flex-column text-center"
             style={{...OVERLAY_STYLES, ...styles}}>
            <div className={configuration.showModal ? "overlay-modal" : ""}>
                {configuration.icon && <img src={configuration.icon} />}
                <h2 style={{whiteSpace: "pre-wrap"}} className="m-4">{configuration.headline}</h2>
                <div className="red-line"></div>
                {configuration.text && <p className="m-4" style={{whiteSpace: "pre-wrap"}}>{configuration.text}</p>}
                {configuration.buttons && (
                    <p>{configuration.buttons.map((button, index) => (
                        <button key={`button-${index}`} className={`btn btn-${button.type} m-3`} onClick={button.onClick}>
                            {button.text}
                        </button>
                    ))}</p>
                )}
            </div>
        </div>
    );
}
