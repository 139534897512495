import {CSSProperties, useEffect, useMemo, useState} from "react";
import {ValidationResultDto} from "./types";
import {Star} from "./Star";
import {ExerciseService} from "./ExerciseService";

type Props = {
    validationResult: ValidationResultDto,
    isFirstInvalid: boolean,
    index: number,
    hintNumber: number | null,
    exerciseId: string,
    onError: (res: { status: number }) => void
};

const questionInfoHintStyle: CSSProperties = {
    textAlign: "right",
    marginRight: "1em",
    marginBottom: "1em",
    marginTop: "-2rem"
};

const questionInfoImageStyle: CSSProperties = {
    width: "2em",
    paddingTop: "0.25em"
}

export const ValidationItem = ({validationResult, isFirstInvalid, index, hintNumber, exerciseId, onError}: Props) => {
    const [open, setOpen] = useState(false);
    const [isAlreadyValid, setIsAlreadyValid] = useState(false);

    const {validationSettings, valid} = validationResult;
    const [hints, setHints] = useState(validationSettings.hints);
    const [hasAdditionalHints, setHasAdditionalHints] = useState(validationSettings.hasAdditionalHints);

    useEffect(() => {
        if (valid && !isAlreadyValid) {
            setOpen(false);
            setIsAlreadyValid(true);
        }
    }, [isAlreadyValid, valid]);

    const backgroundColor = useMemo(() => {
        if (validationSettings.level === 'HINT') {
            if (valid) {
                return 'success';
            }

            if (isFirstInvalid) {
                return 'primary';
            }
        } else if (validationSettings.level === 'ERROR') {
            return 'danger';
        } else if (validationSettings.level === 'WARNING') {
            return 'warning';
        }
        return 'secondary';
    }, [isFirstInvalid, valid, validationSettings.level]);

    const star = useMemo(() => {
        if (validationSettings.level !== 'HINT') {
            return <></>;
        }

        const completion = valid ? (validationSettings.numberOfHintsUsed > 0 ? 50 : 100) : 0;
        return <span className="me-2"><Star completion={completion}/></span>;
    }, [validationSettings.level, valid]);

    const requestHint = () => {
        if (validationSettings.hintNumber != null) {
            ExerciseService.requestHint(exerciseId, validationSettings.hintNumber)
                .then((hintResponseDto) => {
                    setHints(hintResponseDto.hints);
                    setHasAdditionalHints(hintResponseDto.hasAdditionalHints);
                })
                .catch(onError);
        }
    }

    const showOpen = open || isFirstInvalid || backgroundColor === 'danger' || backgroundColor === 'warning';

    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id={`heading-${index}`}>
                <button
                    className={`accordion-button bg-${backgroundColor} text-bg-${backgroundColor}${!showOpen ? " collapsed" : ""}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${index}`}
                    aria-expanded={showOpen ? "true" : "false"}
                    aria-controls={`collapse-${index}`}
                    onClick={() => setOpen(!open)}>
                    {star} {hintNumber && `${hintNumber}. `}{validationSettings.title}
                </button>
            </h2>
            {showOpen && (
                <div id={`collapse-${index}`}
                     className="accordion-collapse collapse show"
                     aria-labelledby={`heading-${index}`}
                     data-bs-parent="#validationAccordion"
                >
                    <div className="accordion-body">
                        {validationResult.validationMessage && (
                            <p><strong>{validationResult.validationMessage}</strong></p>
                        )}
                        <p>{validationSettings.description}</p>
                    </div>
                    {hasAdditionalHints && !valid && <div style={questionInfoHintStyle}>
                        <img src="/icon-question.svg" alt="Tip" style={{...questionInfoImageStyle, cursor: "pointer"}}
                             onClick={requestHint} title="Show a hint, but you won't get the entire star anymore."/>
                    </div>}
                    {hints && hints.length > 0 && (
                        <>
                            <div style={{...questionInfoHintStyle, height: "0px"}}>
                                <img src="/icon-info.svg" alt="Tip" style={questionInfoImageStyle}/>
                            </div>
                            <div className="accordion-body hints">
                                <ol>
                                    {hints.map(({message}: any) => <li>{message}</li>)}
                                </ol>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );

}
