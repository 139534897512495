import {useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar, faStarHalf} from '@fortawesome/free-solid-svg-icons';
import "./Star.css";

type Props = {
    completion: number
};

export const Star = ({completion}: Props) => {
    const icon = useMemo(() => {
        if (completion == 0) {
            return faStar;
        } else if (completion == 100) {
            return faStar;
        } else {
            return faStarHalf;
        }
    }, [completion]);
    const className = useMemo(() => {
        return completion === 0 ? "star-none" : completion === 100 ? "star-full" : "star-half";
    }, [completion]);

    return <span className={className}>
        <FontAwesomeIcon icon={icon}/>
    </span>;
}