import {FunctionComponent, MutableRefObject, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";

type ToastMessage = {
    header: string;
    body: string;
}

export type ToastOperations = {
    addToast: (toast: ToastMessage) => void;
};

type ToastProps = {
    forwardRef: MutableRefObject<ToastOperations | null>
};

const Toast: FunctionComponent<ToastProps> =
    ({forwardRef}) => {

        const [toasts, setToasts] = useState<ToastMessage[]>([]);
        const toastsRef = useRef(toasts);

        useEffect(() => {
            toastsRef.current = toasts;
        }, [toasts]);

        const addToast = useCallback((toast: ToastMessage) => {
            setToasts([toast, ...toasts]);

            const expireLastToast = (timeout: number) => {
                setTimeout(() => {
                    const newToasts = [...toastsRef.current];
                    newToasts.splice(-1);
                    setToasts(newToasts);
                }, timeout);
            }
            expireLastToast(5000);
        }, [toasts]);

        const getHandlerFunctions = () => ({
            addToast
        })

        useImperativeHandle(forwardRef, getHandlerFunctions, [addToast]);


        return <div style={{position: "absolute", bottom: 0, right: 0}}>
            {toasts.map((toast, i) => {
                return <div className="toast show bg-danger m-2" key={i}>
                    <div className="toast-header">
                        {toast.header}
                    </div>
                    <div className="toast-body">
                        {toast.body}
                    </div>
                </div>;
            })}

        </div>;
    };

export {Toast};