import {useEffect, useRef} from "react";

type Props = {
    timeout?: number;
    onTimerComplete?: () => void,
}

export const useTimer = ({timeout, onTimerComplete}: Props) => {
    const timer = useRef<number | undefined>();

    useEffect(() => {
        if (timeout) {
            timer.current = window.setTimeout(() => {
                onTimerComplete && onTimerComplete();
                timer.current = undefined;
            }, timeout);
        }
        return () => {
            window.clearTimeout(timer.current);
            timer.current = undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeout])

    return timer.current;
};
