import {HttpClient} from "../http";
import {ExerciseInformationDto, ExerciseStatusDto, HintResponseDto} from "./types";

export class ExerciseService extends HttpClient {

    static resetExercise(exerciseInformationId: string) {
        return this.doPut(`/exercise-api/exercise/${exerciseInformationId}/reset`);
    }

    static requestHint(exerciseId: string, hintNumber: number): Promise<HintResponseDto> {
        return this.toJson(this.doPostJson(`/exercise-api/exercise/${exerciseId}/request-hint`, {
            hintNumber
        }));
    }

    static loadExercise(exerciseId: string): Promise<ExerciseInformationDto> {
        return this.toJson(this.doPostJson("/exercise-api/exercise", {exerciseId}));
    }

    static gradeExercise(exerciseInformationId: string): Promise<ExerciseStatusDto> {
        return this.toJson(this.doPut(`/exercise-api/exercise/${exerciseInformationId}`));

    }
}
