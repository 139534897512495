import {ValidationItem} from "./ValidationItem";
import {useMemo} from "react";
import {ValidationResultDto} from "./types";

type Props = {
    validationResults: ValidationResultDto[],
    exerciseId: string,
    onError: (res: { status: number }) => void
};

const filterValidErrorsAndWarning = (validationResults: ValidationResultDto[]) => validationResults
    .filter(result => !(['ERROR', 'WARNING'].includes(result.validationSettings.level) && result.valid));

export const ValidationResult = ({validationResults, exerciseId, onError}: Props) => {

    const filteredResults = useMemo(() => filterValidErrorsAndWarning(validationResults), [validationResults]);

    const firstInvalidResultIndex = useMemo(() => {
        for (let i = 0; i < filteredResults.length; i++) {
            if (!filteredResults[i].valid) {
                return i;
            }
        }
        return -1;
    }, [filteredResults]);

    let hintNumber = 1;

    return (
        <div className="accordion" id="validationAccordion">
            {filteredResults
                .map((result, index) =>
                    <ValidationItem
                        validationResult={result}
                        key={`item-${index}`}
                        isFirstInvalid={firstInvalidResultIndex === index}
                        index={index}
                        hintNumber={result.validationSettings.level === 'HINT' ? (hintNumber++) : null}
                        exerciseId={exerciseId}
                        onError={onError}
                    />)}
        </div>
    );
}
