import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useRef, useState} from "react";
// @ts-ignore
import SockJsClient from 'react-stomp';

export type SockJsState = {
    socketConnected: boolean;
    message?: any;
    sendMessage: (topic: string, payload: any) => void;
    setSocketUrl: Dispatch<SetStateAction<any | null>>;
};

const SockJsContext = createContext<SockJsState>({
    setSocketUrl: () => {
    },
    sendMessage: () => {
    },
    socketConnected: false
});

export const useSockJs = () => useContext(SockJsContext);

type Props = {
    children?: ReactNode;
}
export const SockJsContextWrapper = ({children}: Props) => {
    const client = useRef();

    const [socketUrl, setSocketUrl] = useState<string | null>(null);

    const [message, setMessage] = useState<any | null>(null);

    const [socketConnected, setSocketConnected] = useState(false);
    const onConnect = () => setSocketConnected(true);

    const sendMessage = (topic: string, payload: any) => (client.current as any)?.sendMessage(topic, payload);

    return <>
        {socketUrl && (
            <SockJsClient
                url={socketUrl}
                topics={['/user/queue/validation']}
                ref={client}
                onConnect={onConnect}
                onDisconnect={() => setSocketConnected(false)}
                onMessage={setMessage}
                debug={false}
            />
        )}
        <SockJsContext.Provider value={{
            sendMessage,
            socketConnected,
            message,
            setSocketUrl
        }}>
            {children}
        </SockJsContext.Provider>
    </>
}
