import React, {useEffect, useState} from "react";
import {LessonDto, LessonListDto, NewLessonDto} from "./types";
import {Link} from "react-router-dom";
import {LessonService} from "./LessonService";

const NEW_LESSON_INITIAL_STATE = {
    name: "",
    key: "",
    description: ""
};
export const ContentList = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const [lessons, setLessons] = useState<LessonDto[]>([]);
    const [returnUrl, setReturnUrl] = useState<string>("");
    const [newLesson, setNewLesson] = useState<NewLessonDto>(NEW_LESSON_INITIAL_STATE)


    useEffect(() => {
        if (loading) {
            LessonService.getLessons()
                .then((result: LessonListDto) => {
                    setLoading(false);
                    setLessons(result.lessons);
                    setReturnUrl(result.returnLink);
                });
        }
    }, [loading]);

    const setName = (name: string) => setNewLesson(prev => ({...prev, name}));
    const setKey = (key: string) => setNewLesson(prev => ({...prev, key}));
    const setDescription = (description: string) => setNewLesson(prev => ({...prev, description}));
    const onCreateLessonClick = () => {
        LessonService.createLesson(newLesson)
            .then(() => {
                setNewLesson(NEW_LESSON_INITIAL_STATE);
                setLoading(true);
            });
    }

    return <div className="m-2">
        <table className="table table-striped small">
            <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Key</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            {lessons.map(lesson => (
                    <tr key={lesson.id}>
                        <td>{lesson.name}</td>
                        <td>{lesson.key}</td>
                        <td className="text-end">
                            <form method="POST" action={returnUrl}>
                                <div className="btn-group" role="group">
                                    <Link to={`/lti/content/${lesson.id}`}
                                          className="btn btn-sm btn-primary">Edit</Link>
                                    <input type="hidden" name="JWT" value={lesson.jwt}/>
                                    <input type="submit" value="Select" className="btn btn-sm btn-secondary ms-1"/>
                                </div>
                            </form>
                        </td>
                    </tr>
                )
            )}
            <tr>
                <td><input type="text" name="name" value={newLesson.name} className="form-control form-control-sm"
                           onChange={e => setName(e.target.value)}/></td>
                <td><input type="text" name="key" value={newLesson.key} className="form-control form-control-sm"
                           onChange={e => setKey(e.target.value)}/></td>
                <td className="text-end"><input type="button" value="Add" className="btn btn-sm btn-outline-secondary"
                                                onClick={onCreateLessonClick}/></td>
            </tr>
            </tbody>
        </table>
    </div>
};