import {ValidationResultDto} from "./types";
import {Star} from "./Star";
import React from "react";
import {useExerciseInfo} from "../hooks/useExerciseInfo";

type Props = { validationResults: ValidationResultDto[] };

export const StarSummary = ({validationResults}: Props) => {

    const {currentStars, maxStars} = useExerciseInfo({validationResults});

    return <>
        <span className="me-2"><Star completion={100}/></span>
        {currentStars} / {maxStars}
    </>;
}
