import {LessonDetailDto, LessonListDto, NewLessonDto} from "./types";
import {HttpClient} from "../http";

export class LessonService extends HttpClient {
    static loadLesson(exerciseId: string): Promise<LessonDetailDto> {
        return this.toJson(this.doGet(`/exercise-api/definitions/${exerciseId}`));
    }

    static getLessons(): Promise<LessonListDto> {
        return this.toJson(this.doGet("/exercise-api/definitions"));
    }

    static createLesson(newLesson: NewLessonDto) {
        return this.doPostJson("/exercise-api/definitions", newLesson);
    }

    static saveLesson(exerciseId: string, lessonContent: LessonDetailDto) {
        return this.doPutJson(`/exercise-api/definitions/${exerciseId}`, lessonContent);
    }
}
