export class HttpClient {

    protected static getDefaultRequestParams(): RequestInit {
        return {}
    }

    protected static getOptionsWithDefaultParams(options?: RequestInit): RequestInit {
        const defaultRequestParams = this.getDefaultRequestParams();
        return {
            headers: {
                ...defaultRequestParams.headers,
                ...options?.headers,
            },
            ...defaultRequestParams,
            ...options
        }
    }

    protected static toJson(responsePromise: Promise<Response>): Promise<any> {
        return responsePromise.then(res => res.json());
    }

    protected static handleResponse(responsePromise: Promise<Response>): Promise<Response> {
        return responsePromise.then((res) => {
            if (res.ok) {
                return res;
            }
            if (res.status === 401 || res.status === 403) {
                this.handleForbidden();
            }
            return Promise.reject(res);
        })
    }

    private static handleForbidden() {
        const pathName = window.location.pathname;
        if (pathName.startsWith('/exercise/')) {
            const exerciseId = pathName.substring('/exercise/'.length);
            this.redirectToExerciseStartPage(exerciseId);
        } else {
            this.handleGenericRedirect();
        }
    }

    private static redirectToExerciseStartPage(exerciseId: string) {
        fetch(`/exercise-api/definitions/${exerciseId}`, this.getOptionsWithDefaultParams())
            .then((response) => {
                if (response.ok) {
                    return response;
                } else {
                    this.handleGenericRedirect();
                    return Promise.reject();
                }
            })
            .then(response => response.json())
            .then(exerciseInformation => {
                window.location.href = '/load/' + exerciseInformation.key;
            });
    }

    private static handleGenericRedirect() {
        const exerciseReturnLink = sessionStorage.getItem("EXERCISE_RETURN_LINK");
        if (exerciseReturnLink !== null) {
            window.location.href = exerciseReturnLink;
        }
    }

    protected static doGet(url: string, options?: RequestInit): Promise<Response> {
        return this.handleResponse(
            fetch(url, this.getOptionsWithDefaultParams(options))
        );
    }

    protected static doPost(url: string, options?: RequestInit): Promise<Response> {
        return this.handleResponse(
            fetch(
                url,
                {
                    method: "POST",
                    ...this.getOptionsWithDefaultParams(options),
                }
            )
        );
    }

    protected static doPostJson(url: string, body: any, options?: RequestInit): Promise<Response> {
        return this.handleResponse(
            fetch(
                url,
                {
                    method: "POST",
                    ...this.getOptionsWithDefaultParams({
                        headers: {
                            "Content-Type": "application/json",
                            ...options?.headers
                        },
                        ...options
                    }),
                    body: JSON.stringify(body),
                }
            )
        );
    }

    protected static doPut(url: string, options?: RequestInit): Promise<Response> {
        const defaultRequestParams = this.getDefaultRequestParams();
        return this.handleResponse(
            fetch(
                url,
                {
                    method: "PUT",
                    headers: {
                        ...defaultRequestParams.headers,
                        ...options?.headers
                    },
                    ...options,
                    ...defaultRequestParams
                }
            )
        );
    }

    protected static doPutJson(url: string, body: any, options?: RequestInit): Promise<Response> {
        return this.handleResponse(
            fetch(
                url,
                {
                    method: "PUT",
                    ...this.getOptionsWithDefaultParams({
                        headers: {
                            "Content-Type": "application/json",
                            ...options?.headers
                        },
                        ...options
                    }),
                    body: JSON.stringify(body),
                }
            )
        );
    }


}
