import React from 'react';
import {Exercise} from "./exercise/Exercise";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {ContentList} from "./content/ContentList";
import {ContentEdit} from './content/ContentEdit';
import {SockJsContextWrapper} from "./hooks/useSockJs";
import {Load} from "./load/Load";
import {RequireAuthentication} from "./authentication/RequireAuthentication";
import {HomePageRedirect} from "./home/HomePageRedirect";

const App = () => {

    const inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    return (
        <SockJsContextWrapper>
            <Router>
                <div>
                    <Routes>
                        <Route path="/exercise/:exerciseId" element={inIframe()
                            ? <a href={window.location.href} target="_blank" rel="noreferrer">Please click here to open
                                the exercise.</a>
                            : <RequireAuthentication><Exercise/></RequireAuthentication>}/>
                        <Route path="/load/:exerciseKey" element={<Load />} />
                        <Route path="/lti/content/:exerciseId" element={<RequireAuthentication><ContentEdit/></RequireAuthentication>}/>
                        <Route path="/lti/content" element={<RequireAuthentication><ContentList/></RequireAuthentication>}/>
                        <Route path="/" element={<HomePageRedirect />}></Route>
                    </Routes>
                </div>
            </Router>
        </SockJsContextWrapper>
    );

}
export default App;
