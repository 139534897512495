import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ValidationResult} from "./ValidationResult";
import {Overlay, OverlayConfiguration} from "../components/Overlay";
import {useNavigate, useParams} from "react-router-dom";
import {useSockJs} from "../hooks/useSockJs";
import {capitalizeFirstCharacter} from "../utils/stringUtils";
import {ContextType, ExerciseInformationDto, ValidationResultDto} from "./types";
import {ExerciseService} from "./ExerciseService";
import {UserDetailsService} from "../user/UserDetailsService";
import {StarSummary} from "./StarSummary";
import {Toast, ToastOperations} from "../components/Toast/Toast";
import {useExerciseInfo} from "../hooks/useExerciseInfo";

const contextToName = (contextName: string) => `Flowable ${capitalizeFirstCharacter(contextName)}`

const InitialOverlay = () => <Overlay
    configuration={{
        show: true,
        headline: "Loading your exercise...",
        text: "Hang on a second, the exercise will be ready shortly!",
        size: "full"
    }} onClose={() => {
}}/>

export const Exercise = () => {
    const {exerciseId} = useParams();
    const navigate = useNavigate();
    const {message, setSocketUrl} = useSockJs();
    const toasts = useRef<ToastOperations | null>(null);

    const [loading, setLoading] = useState<boolean>(true);
    const [exerciseInformation, setExerciseInformation] = useState<ExerciseInformationDto | null>(null);

    const [context, setContext] = useState<ContextType>("design");
    const [isGraded, setIsGraded] = useState(false);
    const prevContext = useRef<ContextType>("design");

    useEffect(() => {
        if (exerciseInformation?.socketUrl) {
            setSocketUrl(exerciseInformation?.socketUrl);
        }
    }, [exerciseInformation?.socketUrl, setSocketUrl]);
    const [validationResults, setValidationResults] = useState<ValidationResultDto[]>([]);

    useEffect(() => {
        if (message) {
            setValidationResults(message);
            setLoading(false);
        }
    }, [message]);

    const onOverlayClose = () => setOverlayConfiguration(prev => ({
        ...prev,
        closeAfter: undefined,
        show: false
    }));

    const [overlayConfiguration, setOverlayConfiguration] = useState<OverlayConfiguration>({
        show: true,
        showModal: false,
        headline: "Welcome\n" +
            "to the Flowable Design Exercise",
        text: "This is a self-guided exercise. You will find the instructions on the right-hand side of the screen.\n" +
            "On the left, you will see Flowable Design.\n\nAre you ready?",
        buttons: [
            {
                text: "Start now",
                onClick: onOverlayClose,
                type: "primary"
            }
        ],
        size: "full"
    });

    const iframeSrc = useMemo(() => {
        if (context === "design") {
            if (exerciseInformation?.designVersion === 4) {
                return `/flowable-design4/#/workspace/default/apps/${exerciseInformation?.appKey}/editor/${exerciseInformation?.scopeType}/${exerciseInformation?.scopeKey}`;
            } else {
                return `/flowable-design/#/editor/${exerciseInformation?.scopeId}?appModelId=${exerciseInformation?.appId}`;
            }
        } else if (context === "work") {
            return "/flowable-work/";
        }
        return undefined;
    }, [context, exerciseInformation?.appId, exerciseInformation?.scopeId, exerciseInformation?.appKey, exerciseInformation?.scopeKey, exerciseInformation?.scopeType, exerciseInformation?.designVersion])

    const onReset = useCallback(
        () => {
            setIsGraded(false);
            return exerciseInformation?.id && ExerciseService.resetExercise(exerciseInformation?.id)
                .then(res => res.ok && navigate(0))
                .catch(errorFunction);
        },
        [exerciseInformation?.id, navigate]
    );

    useEffect(() => {
        if (loading && exerciseId) {
            ExerciseService.loadExercise(exerciseId)
                .then(result => {
                    setExerciseInformation(result);
                    if (result?.socketUrl) {
                        setSocketUrl(result?.socketUrl);
                    }
                })
                .catch(errorFunction);
        }
    }, [loading, exerciseId, setSocketUrl]);

    useEffect(() => {
        if (context !== prevContext.current) {
            prevContext.current = context;
            setOverlayConfiguration({
                show: true,
                headline: "Well done!",
                text: `We are now switching the context to ${contextToName(context)}`,
                closeAfter: 3000,
                size: "flowable"
            });
        }
    }, [context])

    useEffect(() => {
        for (const result of validationResults) {
            if (!result.valid) {
                setContext(result.validationSettings.context);
                return;
            }
        }

        if (validationResults.length > 0) {
            setIsGraded(true);
            exerciseInformation?.id && !isGraded && ExerciseService.gradeExercise(exerciseInformation?.id)
                .then(result => {
                    if (result.completed) {
                        setOverlayConfiguration({
                            show: true,
                            showModal: true,
                            icon: "/icon-endscreen.svg",
                            headline: "Congratulations!",
                            text: "Excellent you completed this session.",
                            buttons: [
                                {
                                    text: "< Restart Exercise",
                                    onClick: onReset,
                                    type: "secondary"
                                },
                                {
                                    text: "Continue >",
                                    onClick: () => {
                                        window.location.href = result.returnLink;
                                    },
                                    type: "primary"
                                }
                            ],
                            size: "full"
                        });
                    }
                })
                .catch(errorFunction)
        }
    }, [exerciseInformation?.id, onReset, validationResults]);

    const {possibleStarsUntilNow, maxStars} = useExerciseInfo({validationResults});
    const [halfScreenAcknowledged, setHalfScreenAcknowledged] = useState(false);

    useEffect(() => {
        if (possibleStarsUntilNow > 0 && possibleStarsUntilNow >= (maxStars / 2) && !halfScreenAcknowledged) {
            setOverlayConfiguration({
                show: true,
                showModal: true,
                icon: "/icon-half-way.png",
                headline: "You're halfway through",
                text: "Complete more tasks to complete the session.\nWe know you can do it :)",
                buttons: [
                    {
                        text: "Continue session",
                        onClick: () => {
                            setHalfScreenAcknowledged(true);
                            setOverlayConfiguration(prev => ({
                                ...prev,
                                closeAfter: undefined,
                                showModal: false,
                                show: false
                            }));
                        },
                        type: "primary"
                    }
                ]
            });
        }
    }, [possibleStarsUntilNow, maxStars, halfScreenAcknowledged]);

    const onResetClick = () => {
        setOverlayConfiguration({
            show: true,
            headline: "Are you sure you'd like to reset this exercise?",
            buttons: [
                {
                    text: "Cancel",
                    onClick: onOverlayClose,
                    type: "dark"
                },
                {
                    text: "Reset",
                    onClick: onReset,
                    type: "danger"
                }
            ],
            size: "full"
        })
    }

    const onReturnClick = () => {
        const exerciseReturnLink = UserDetailsService.getExerciseReturnLink();
        if (exerciseReturnLink) {
            window.location.href = exerciseReturnLink;
        }
    }

    const errorFunction: (res: { status: number }) => void = (res) => {
        setIsGraded(false);
        let toast = {header: "Internal Server Error", body: "Failed to connect to the server. Please try again later."};
        if (res.status === 401 || res.status === 403) {
            toast = {header: "Authentication Expired", body: "Authentication is expired. Please go back and re-open the exercise."};
        }
        toasts.current?.addToast(toast);
    };

    return <>
        {(!exerciseInformation)
            ? (
                <InitialOverlay/>
            ) : (
                <div>
                    <div style={{width: "calc(100% - 480px)", height: "100%", float: "left"}}>
                        <iframe
                            title={contextToName(context)}
                            src={iframeSrc}
                            width="100%" style={{height: "99vh"}}
                        />
                    </div>
                    {validationResults.length > 0 && <div style={{width: "480px", height: "99vh", overflow: "hidden", float: "right"}}>
                        <div style={{display: "flex", justifyContent: "space-around", alignItems: "center", height: "4em"}}>
                            <div><span className="me-2 text-black-50" role="button" title="Back" onClick={onReturnClick}>
                                <img src="/arrow-back.svg" alt="Back" style={{width: "1em"}}/>
                            </span></div>
                            <div style={{fontSize: "11px", fontWeight: "bold", textTransform: "uppercase", letterSpacing: "1.65px"}}>Current
                                Context<br/>{contextToName(context)}</div>
                            <div><StarSummary validationResults={validationResults}/></div>
                            <div>
                                <span className="me-2 text-black-50" role="button" title="Restart the exercise from the beginning"
                                      onClick={onResetClick}>
                                     <img src="/reset.svg" alt="Reset" style={{width: "1.6em"}}/>
                                </span>
                            </div>
                        </div>
                        <div style={{overflow: "auto", height: exerciseInformation.youtubeVideo ? "calc(100% - 4em - 270px)" : "calc(100% - 4em)"}}>
                            <ValidationResult validationResults={validationResults} exerciseId={exerciseInformation.id} onError={errorFunction}/>
                        </div>
                        {exerciseInformation.youtubeVideo &&
                            <iframe width="480" height="270" src={`https://www.youtube.com/embed/${exerciseInformation.youtubeVideo}`}
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen></iframe>}
                    </div>}
                    {loading ? <InitialOverlay/> : <Overlay configuration={overlayConfiguration} onClose={onOverlayClose}/>}
                </div>
            )}
        <Toast forwardRef={toasts}/>
    </>;
}
