export const convertFileToBase64 = (file: any) => {
    return new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result as string);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
        .then(result => result.split(",")[1]);
}
