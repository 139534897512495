import React, {useRef} from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import "./Load.css";
import {Toast, ToastOperations} from "../components/Toast/Toast";

export const Load = () => {
    const {exerciseKey} = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const toasts = useRef<ToastOperations | null>(null);

    const returnUrl = searchParams.get("returnUrl") || "https://flowable.com/";

    const verify = (token: string | null) => {
        if (token) {
            fetch('/exercise-api/user-details', {
                headers: {
                    Authorization: "Basic " + btoa(`recaptcha:${token}`),
                    "X-Custom-Return-Url": returnUrl
                }
            })
                .then((response) => {
                    if (response.ok) {
                        fetch(`/exercise-api/definitions?exerciseKey=${exerciseKey}`)
                            .then(response => response.json())
                            .then(json => json.lessons)
                            .then(lessons => {
                                if (lessons.length === 1) {
                                    navigate(`/exercise/${lessons[0].id}`);
                                } else {
                                    toasts.current?.addToast({header: "Exercise not found", body: "Failed to find exercise, please go back to the documentation and try again."});
                                }
                            })
                    } else {
                        toasts.current?.addToast({header: "Verification failed", body: "Failed to validate that you are a human, please try again."});
                    }
                })
        }
    }

    const sitekey = process.env.REACT_APP_SITE_KEY || "";
    return <>
        <div className="background">
            <div className="container login">
                <div className="content">
                    <img src="/flowable_logo.svg" alt="Flowable logo" className="logo"/>
                    <div className="form-container">
                        <h1>Interactive Documentation</h1>
                        <div className="red-line"></div>
                        <p className="headline">To start the interactive documentation, please confirm that you are a human:</p>
                        <ReCAPTCHA
                            sitekey={sitekey}
                            onChange={verify}
                        />
                    </div>
                </div>
            </div>
        </div>
        <Toast forwardRef={toasts}/>
    </>;
}