import {useEffect} from "react";
import {UserDetailsService} from "../user/UserDetailsService";

export const RequireAuthentication = ({children}: any) => {

    useEffect(() => {
        UserDetailsService.verifyAuthentication();
    }, []);

    return children;
};