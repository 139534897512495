import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {LessonDetailDto} from "./types";
import {JsonEditor} from "../components/JsonEditor";
import {convertFileToBase64} from "../utils/fileUtils";
import {LessonService} from "./LessonService";
import * as schema from "./validations-schema.json";

export const ContentEdit = () => {
    const navigate = useNavigate()
    const {exerciseId} = useParams();

    const [loading, setLoading] = useState(true);
    const [lessonContent, setLessonContent] = useState<LessonDetailDto>({
        id: "",
        key: "",
        name: "",
        description: "",
        validationDefinition: "{}",
        mainModelScopeType: "",
        mainModelScopeKey: "",
        app: "",
        designVersion: 3
    });

    useEffect(() => {
        if (loading) {
            exerciseId && LessonService.loadLesson(exerciseId)
                .then(result => {
                    setLessonContent(result);
                    setLoading(false);
                });
        }
    }, [exerciseId, loading]);

    const changeValidationDefinition = (newDefinition: string) => {
        setLessonContent(prev => ({
            ...prev,
            validationDefinition: newDefinition
        }));
    }

    const saveLessonAndClose = () => {
        exerciseId && LessonService.saveLesson(exerciseId, lessonContent).then(() => navigate("/lti/content"));
    }

    const saveLessonAndPreview = () => {
        exerciseId && LessonService.saveLesson(exerciseId, lessonContent)
            .then(() => {
                window.open(`/exercise/${exerciseId}`, 'exercise-preview');
            });
    }

    const handleAppUpload = async (event: any) => {
        const app = await convertFileToBase64(event.target.files[0]);
        setLessonContent({...lessonContent, app});
    }

    return loading ?
        <div>Loading</div> :
        <div className="m-2">
            <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" value={lessonContent?.name}
                       onChange={e => setLessonContent({...lessonContent, name: e.target.value})}/>
            </div>
            <div className="form-group">
                <label htmlFor="key">Key</label>
                <input type="text" className="form-control" id="key" value={lessonContent?.key}
                       onChange={e => setLessonContent({...lessonContent, key: e.target.value})}/>
            </div>
            <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea className="form-control" id="description" value={lessonContent?.description}
                          onChange={e => setLessonContent({...lessonContent, description: e.target.value})}/>
            </div>
            <div className="form-group">
                <label htmlFor="validationDefinition">Validation Definition</label>
                <JsonEditor
                    value={lessonContent?.validationDefinition}
                    onChange={changeValidationDefinition}
                    mode="code"
                    htmlElementProps={{style: {height: 600}}}
                    schema={schema}
                />
            </div>
            <div className="form-group">
                <label htmlFor="app">App</label>
                <input
                    id="app"
                    type="file"
                    accept=".zip"
                    required
                    name="app"
                    className="form-control"
                    onChange={e => handleAppUpload(e)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="description">Scope Type</label>
                <input type="text" className="form-control" id="mainModelScopeType" value={lessonContent?.mainModelScopeType}
                       onChange={e => setLessonContent({...lessonContent, mainModelScopeType: e.target.value})}/>
            </div>
            <div className="form-group">
                <label htmlFor="description">Scope Key</label>
                <input type="text" className="form-control" id="mainModelScopeKey" value={lessonContent?.mainModelScopeKey}
                       onChange={e => setLessonContent({...lessonContent, mainModelScopeKey: e.target.value})}/>
            </div>
            <div className="form-group">
                <label htmlFor="description">Design Version</label>
                <select className="form-control"
                        id="designVersion" value={lessonContent?.designVersion}
                       onChange={e => setLessonContent({...lessonContent, designVersion: Number(e.target.value)})}>
                    <option value={3} selected={lessonContent.designVersion === 3}>Flowable Design 3</option>
                    <option value={4} selected={lessonContent.designVersion === 4}>Flowable Design 4</option>
                </select>
            </div>
            <div className="form-group mt-1">
                <Link type="button" className="btn btn-light" to="/lti/content">Close</Link>
                <input type="button" className="btn btn-primary float-end" value="Save & Close"
                       onClick={saveLessonAndClose}/>
                <input type="button" className="btn btn-secondary me-1 float-end" value="Save & Preview"
                       onClick={saveLessonAndPreview}/>
            </div>
        </div>;
}
