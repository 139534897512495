import {ValidationResultDto} from "../exercise/types";
import {useMemo} from "react";

type Props = {
    validationResults: ValidationResultDto[];
}

type Result = {
    currentStars: number;
    possibleStarsUntilNow: number;
    maxStars: number;
}


const filterErrorsAndWarning = (validationResults: ValidationResultDto[]) => validationResults
    .filter(result => !(['ERROR', 'WARNING'].includes(result.validationSettings.level)));


export const useExerciseInfo = ({validationResults}: Props): Result => {
    const validResults = useMemo(
        () => filterErrorsAndWarning(validationResults).filter(result => result.valid),
        [validationResults]
    );

    const currentStars = useMemo(() =>
            Math.round(
                validResults
                    .map(result => result.validationSettings.numberOfHintsUsed > 0 ? 0.5 : 1)
                    .reduce((partialSum, n) => partialSum + n, 0)
            ),
        [validResults]
    );
    const possibleStarsUntilNow = useMemo(() =>
            validResults
                .map(() => 1)
                .reduce((partialSum, n) => partialSum + n, 0),
        [validResults]
    );
    const maxStars = useMemo(() => filterErrorsAndWarning(validationResults).length, [validationResults]);

    return {currentStars, possibleStarsUntilNow, maxStars};
};
